export const items = [
  {
    title: "Home",
    icon: "mdi-home-analytics",
    to: "Home",
  },
  {
    title: "거래조회",
    icon: "mdi-credit-card-check-outline",
    to: "transaction",
  },
  {
    title: "대리점관리",
    icon: "mdi-calendar-account",
    to: "dealer",
  },
  {
    title: "가맹점관리",
    icon: "mdi-factory",
    to: "franchise",
  },
  {
    title: "수익현황",
    icon: "mdi-cash",
    to: "salesRevenue",
  },
  {
    title: "대리점정산서",
    icon: "mdi-clipboard-text-outline",
    to: "statementOfAccounts",
  },
  {
    title: "공지사항",
    icon: "mdi-alarm-light-outline",
    to: "notice",
  },
  {
    title: "대리점 등록 요청",
    icon: "mdi-account-multiple-plus-outline",
    to: "agencyRegistration",
  },
  {
    title: "자료실",
    icon: "mdi-file-cabinet",
    href: "http://i-web.kr/unicore/?QfvY1arN",
    target: "_blank",
  },
];
