<template>
  <div>
    <v-navigation-drawer
      v-model="getThemeMode.verticalCompactSidebarDrawer"
      :color="
        $vuetify.theme.dark ? 'dark' : getThemeMode.verticalSidebarDrawerColor
      "
      :dark="getThemeMode.verticalSidebarDrawerColor != 'white' ? true : false"
      width="230px"
      app
      mobile-breakpoint="1120"
      class="shadow-sm rounded-r-md"
      floating
      height="100vh"
      :right="$vuetify.rtl"
    >
      <v-row
        class="fill-height flex-row flex ma-0"
        no-gutters
      >


        <v-list
          class="grow"
          max-width="230"
        >
          <div class="mb-5">
            <div class="text-center my-2 mb-3 font-weight-regular">
              {{ loggedInUser.dealerName }}
              <template v-if="loggedInUser.dealerKind == 33">
                (지사)
              </template>
              <template v-if="loggedInUser.dealerKind == 34">
                (대리점)
              </template>
            </div>
            <div style="display: flex; width:100%;">
              <div style="width: 50%; padding:0px 5px;">
                <v-btn block @click="selectedMenuHandler('내정보보기', 'agency')">내정보보기</v-btn>
              </div>
              <div style="width: 50%; padding:0px 5px;">
                <v-btn block @click="logout">로그아웃</v-btn>
              </div>
            </div>
          </div>


          <template v-for="(item, i) in computedItems">
            <template v-if="item.title != '대리점 등록 요청'">
              <base-item-group
                v-if="item.children"
                :key="`group-${i}`"
                :item="item"
              />
              <base-item
                v-else
                :key="`item-${i}`"
                :item="item"
              />
            </template>
            <!--      - “지사”로 로그인 하더라도 현재 본사 관리자에 대응 메뉴 및 기능이 구현되지 않아 메뉴 미노출 처리하고, 관리자 구성 완료 후 수정 및 노출 예정        -->
            <!--              <template v-else>-->
            <!--                <template v-if="loggedInUser.dealerKind == 33">-->
            <!--                  <base-item-group-->
            <!--                    v-if="item.children"-->
            <!--                    :key="`group-${i}`"-->
            <!--                    :item="item"-->
            <!--                  />-->

            <!--                  <base-item-->
            <!--                    v-else-->
            <!--                    :key="`item-${i}`"-->
            <!--                    :item="item"-->
            <!--                  />-->
            <!--                </template>-->
            <!--              </template>-->
          </template>
        </v-list>
      </v-row>
    </v-navigation-drawer>
    <!-- end of sidebar drawer -->

    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      left
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn
            icon
            color=""
            @click.stop="userDrawer = !userDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            text="Logout"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
          />
        </div>
      </template>
    </v-navigation-drawer>

    <!-- notificationDrawer  -->
    <v-navigation-drawer
      v-model="notificationDrawer"
      fixed
      left
      height="100%"
      temporary
      floating
      width="350"
    >
      <notification-drawer>
        <template v-slot:notificationDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="notificationDrawer = !notificationDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            text="View All Notifications"
            block
            bg-color="primary lighten-5 primary--text"
          />
        </div>
      </template>
    </v-navigation-drawer>
    <!-- searchDrawer -->
    <v-navigation-drawer
      v-model="searchDrawer"
      fixed
      left
      height="100%"
      temporary
      floating
      width="380"
    >
      <search-drawer>
        <template v-slot:searchDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="searchDrawer = !searchDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </search-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { items } from '../../data/navigation'
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    components: {
      UserDrawer: () => import('../common-drawer/UserDrawer.vue'),
      NotificationDrawer: () => import('../common-drawer/NotificationDrawer.vue'),
      SearchDrawer: () => import('../common-drawer/SearchDrawer.vue'),
    },
    data () {
      return {
        drawer: true,
        items: items,
        userDrawer: false,
        notificationDrawer: false,
        searchDrawer: false,
        items2: ['mdi-home', 'mdi-play'],
        imtemsAside: ['mdi-play'],
      }
    },
    computed: {
      ...mapGetters(['getThemeMode', 'loggedInUser']),
      computedItems () {
        return this.items.map(this.mapItem)
      },
    },
    methods: {
      ...mapActions(['changeCompactVerticalSidebarDrawer']),
      ...mapMutations(['clearUser']),
      ...mapMutations(['setSelectedMenu']),
      toggleSidebar () {
        this.changeCompactVerticalSidebarDrawer()
      },
      selectedMenuHandler (title, url) {
        this.setSelectedMenu(title)
        this.$router.push(url)
      },
      logout () {
        if (confirm('로그아웃 하시겠습니까') == true) {
          this.clearUser()
          this.$router.push('/app/sessions/log-in')
        }
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: item.title,
        }
      },
    },
  }
</script>
